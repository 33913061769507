// @import "~bootstrap/scss/functions";
// @import  "~bootstrap/scss/variables";
// @import  "~bootstrap/scss/mixins/_breakpoints";
// @import  "~bootstrap/scss/bootstrap";
@import './../../styles/var';
@import './../../styles/mixins';

.text_input {
    width: 340px;
    margin-bottom: 16px;
    margin-right: 54px;
    max-width: 100%;

    @include media-breakpoint-down-sm {
        margin-right: 0px;
    }
}

.checkboxe_cont {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F1F1F1;
    padding-bottom: 10px;
    width: 339px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
    margin-right: 17px;
    cursor: pointer;

    .checkboxe {
        @include size(28px);
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 10px;
    }
}

.map_select {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    border-bottom: 1px solid #F1F1F1;
    padding-bottom: 10px;
    width: 339px;
    max-width: 100%;
    margin-bottom: 15px;
    cursor: pointer;

    .label {
        font-weight: $regular;
        font-size: $fz-12;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: $text-color;
    }

    img {
        margin-right: 15px;
    }
}

.error,
.first_childe_error {
    position: absolute;
    bottom: -18px;
    font-size: 0.75rem;
    color: $error-color;
    // &:first-child{
    //     left: -12px;
    // }
}

.first_childe_error {
    left: 0px;
}

.number_input,
.number_focused_input {
    display: flex;
    width: 100%;
    height: 44px !important;
    background: #F4F4F4;
    border-radius: 5px;
    position: relative;
    // &:first-child{
    //     .error{
    //         left: -12px;
    //     }
    // }
    // margin-bottom: 8px;

    input {
        height: 100%;
        line-height: 44px;
        border: none;
        padding-left: 10px;
        background: #F4F4F4;
        outline: none !important;
        border-radius: 5px;

        &::placeholder {
            color: $gray-color;
            font-size: $fz-14;
            height: 100%;
            text-align: center;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.number_focused_input {
    input {
        background: $white;
    }
}

.card {
    &-input {
        background: transparent;
        border: none;
        outline: none;
        padding: 10px 0;
    }
}

.select-input{
    & > div{
        & > label{
            font-size: 20px;
            padding: 20px 0 0 10px;
            position: absolute;
            z-index: 20;
        }
    }
}
.flag{
    width: 35px;
    height: 20px;
  }