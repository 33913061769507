@import './styles/var';
@import './styles/mixins';
.section_button{
  @include backgroundSize(contain, center, no-repeat);
  @include size(80px, 24px);
  margin: 15px;
}
.start_language{
  position: absolute;
  right: 60px;
  top: 10px;
}
.world{
  width: 25px;
  height: fit-content;
  margin-right: -7px;
  z-index: 0;
}

.show_login_with_phoneNumber{
  margin-top: 20px;
}
.show_login_with_phoneNumber_label{
  font-weight: $medium;
  color: #939393 !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.login {
    background: url("../images/bg.png");
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include backgroundSize(cover, center, no-repeat);
    @include media-breakpoint-down-sm(){
      background: url("../images/bg-mobile.png");
      @include backgroundSize(cover, center, no-repeat);
    }
    .forget_title{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #939393;
      margin-bottom: 27px;
    }
  .agree_message{
    color: red;
    text-align: center;
    margin-top: 15px;
  }
  .button_cont_code{
    display: flex;
    flex-direction: column;
    margin-top: 113px;
      .send_code{
        font-weight: $regular;
        font-size: $fz-16;
        line-height: 19px;
        /* identical to box height */
        text-align: center;
        display:inline-block;
        border-bottom:1px solid #939393;
        padding-bottom:1px;
        width: fit-content;
        margin: auto;
        // text-decoration-line: underline;
        color: #939393;
        margin-top: 27px;
        cursor: pointer;
      }
  }
  .post_error{
    position: relative;
  }
  .post_code_error{
    position: relative;
    .error_message{
      top: 62px;
      bottom: 0;
      height: fit-content;
      white-space: nowrap;
    }
  }
  .button_cont{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
    button{
      @include size(303px, 52px);
      font-size: $fz-18 !important;
      font-weight: $regular;
      line-height: 21px;
      border-radius:50px;
      margin: 0;
      margin-top: 21px;
    }

  }
  .error_message{
    color: red;
    margin-bottom: 1rem;
    position: absolute;
    // top: -28px;
    bottom: -33px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
  }
  .login_logo{
      margin-bottom: 90px;
  }
  .regis_logo{
      margin-bottom: 31px;
  }
  .start{
    .bottom_text{
      width: 303px;
      text-align: center;
    }
    .login_logo{
      margin-bottom: 49px;
    }
  }
  .bottom_text, .title{
    color: #A4A4A4;
    font-size: $fz-16;
    font-weight: $regular;
    line-height: 19px;
    cursor: pointer;
  }
  .title{
    font-weight: $medium;
    color: #939393 !important;
    text-decoration: underline !important;
   
  }
  .master_client_cont{
      display: flex;
      justify-content: space-between;
      width: 361px;
      margin-bottom: 30px;
  }
  .regis_title,.regis_title_bottom{
    font-weight: $medium;
    font-size: $fz-20;
    line-height: 23px;
    text-align: center;
    color: #939393;
    margin-bottom: 29px;
    width: 318px;
  }
  .regis_title_bottom{
    font-size: $fz-16;
  }
  .phone_valid{
    color: red;
    position:absolute;
    font-size: 11px;
    bottom: -15px;
    left: 75px;
    text-align: center;
  }
}
.button_cont_forget{
  display: flex;
  flex-direction: column;
  button{
    background:$white;
    color: $gray-color;
    font-size: $fz-16;
    &:first-child{
      margin-bottom: 20px;
    }
  }
}
.forget_input_phone_email{
  margin-top: 28px;
}
.go_home{
  text-decoration: underline;
  cursor: pointer;
  font-weight: $bold;
}