@import './../../../styles/var';
@import './../../../styles/mixins';
.forget_input_code{
    margin-bottom: 20px !important;
  }
.login_input_cont {
    @include size(303px, 50px);
    border-radius: 50px;
    border: 1px solid #F5CCD7;
    background: white;
    // background-color: white;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 303px) {
        width: 100%;
    }

    .number_input {
        height: 100%;
        position: relative;
        width: 100%;

        input {
            @include size(100%);
            border-radius: 50px;
            text-align: center;
            font-size: $fz-20;

            &::placeholder {
                font-size: 14px;
                display: flex;
                align-items: center;
            }
        }
    }

    input {
        height: 100%;
        border: none;
        outline: none;
        border-radius: 50px;
        padding: 5px;
    }
}

.input_validation {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down-sm {
        width: 100%;
    }

    .login_input {
        margin-left: 14px;
        height: 100%;
        width: 77%;
        border: none;
        outline: none;
        border-radius: 50px;

        ::placeholder {
            color: #A4A4A4;
            font-size: $fz-16;
            font-weight: $regular;
            line-height: 19px;
        }
    }

    .error_message {
        color: red;
        position: absolute;
        bottom: 4px;
        left: 75px;
        font-size: 11px;
    }
}