@mixin imageSize($size, $pozition) {
    object-fit: $size;
    object-position: $pozition;
}

@mixin backgroundSize($size, $pozition, $repeat) {
    background-size: $size;
    background-repeat: $repeat;
    background-position: $pozition;
}

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin media-breakpoint-down-xxl() {
    @media (max-width: 1400px) {
        @content
    }
}

@mixin media-breakpoint-down-xs() {
    @media (max-width: 1200px) {
        @content
    }
}

@mixin media-breakpoint-down-lg() {
    @media (max-width: 1199.98px) {
        @content
    }
}

@mixin media-breakpoint-down-md() {
    @media (max-width: 991.98px) {
        @content
    }
}

@mixin media-breakpoint-down-sm() {
    @media (max-width: 767.98px) {
        @content
    }
}

@mixin media-breakpoint-down-xsm {
    @media (max-width: 540px) {
        @content
    }
}