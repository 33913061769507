@import './../../styles/var';
@import './../../styles/mixins';

.lg_button{
    color: $white ;
    background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%);
    @include size(340px, 44px);
    border-radius:50px;
    font-size: $fz-16 ;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    &:hover{
        box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
    }
    img{
        margin-right: 9px;
    }
    @include media-breakpoint-down-sm{
        @include size(100%, 44px);
    }
}
.check{
    margin-right: 5px;
}
.main_disabed_btn{
    background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%);
    opacity: 0.4;
}
.main_btn, .main_disabed_btn{
    color: $white ;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:  $fz-18;
    background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%);
    @include size(303px, 52px);
    border-radius:50px;
    border: none;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    &:hover{
        box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
    }
}
.small_btn{
    color: $white ;
    background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%);
    @include size(120px, 32px);
    border-radius:50px;
    border: none;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    &:hover{
        box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
    }
}
.active_btn{
    background: linear-gradient(90deg, #63D290 0%, #71E9A1 100%) !important;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    @include size(120px, 32px);
    border-radius:50px;
    border: none;
    color: $white
}
.section .btn_lg{
    @include size(275px,59px);
    @extend %font-m-bold;
    font-size: $fz-20 ;
    line-height: 24px ;
    color: $white ;
    background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%);
}
.btn_landing{
    @include size(275px,59px);
    @extend %font-m-bold;
    font-size: $fz-20 !important;
    line-height: 24px !important;
    color: $white ;
    background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%);
    border: none;
    border-radius: 5px;
    @include media-breakpoint-down-sm(){
        @include size(167px,40px);
        font-size: $fz-12 !important;
        line-height: 15px;
    }
    &:hover{
        box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
    }
}
.button_outline{
    @include size(275px,59px);
    @extend %font-m-bold;
    font-size: $fz-20 !important;
    line-height: 24px !important;
    border-radius: 5px;
    border: 2px solid $primary-light-shade-color;
    display: flex;
    justify-content: center;
    align-items: center;
    background:$white;
    color: $primary-light-shade-color;
    margin-right: 20px;
    &:hover{
        color: $white; 
        background: linear-gradient(90deg
, $primary-color 0%, $primary-light-shade-color 96.37%);
    }
    @include media-breakpoint-down-lg(){
        margin-bottom: 20px;
    }
    @include media-breakpoint-down-sm(){
        @include size(167px,40px);
        font-size: $fz-12 !important;
        line-height: 15px;
    }
}

.contact-form{
    .btn_lg{
        @include size(355px,40px);
        margin-top: 54px;
        
    }
}
.master-information {
    .btn_lg {
        @include size(340px,44px);
        font-weight: $medium;
        font-size: $fz-16;
        line-height: 19px;
        border-radius: 22px;
    }
}
.master-service{
    .btn_lg {
        @include size(105px,32px);
        font-size: $fz-12 !important;
        line-height: 14px !important;
        border-radius: $fz-16;
        font-weight: $medium;
        margin-bottom: 0px
    }
}

.selected_masters button, .cancel button{
    @include size(147px,44px);
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    font-weight: $medium;
    font-size: 13px;
    line-height: 15px;
    margin-top: 44px;
    @include media-breakpoint-down-sm(){
        margin-top: 28px;
    }
    &:first-child{
        margin-right: 10px;
    }
    &:last-child{
        background: $white;
        color: $primary-color !important;
    }
}
.payment-cards{
    button{
        @include size(277px,52px);
        border-radius: 50px;
        box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
        &:first-child{
            margin-bottom: 14px;
        }
        &:last-child{
            background: $white ;
            color:  #747474 !important;
        }
    }
}