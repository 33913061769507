// colors
$primary-color:#F9989C;
$primary-light-shade-color:#FBB0B5;
$gray-color:#A4A4A4;
$text-color:#464646;
$background-color:#FEFCFA; 
$title-color:#977575;
$white:#FFFFFF;
$error-color:#f44336;
$black:#000000;

// font sizes
$fz-10: 10px;
$fz-12: 12px;
$fz-13: 13px;
$fz-16:16px;
$fz-14:14px;
$fz-18:18px;
$fz-20:20px;

// font weights
$regular:400;
$medium:500;
$medium2:600;
$bold:700;

%font-m-bold { 
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
%font-r-bold {
    font-family: 'Roboto'; 
    font-weight: 700;
}